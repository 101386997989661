
import {
    defineComponent, ref, Ref,
    PropType, watch
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertySubRouter, propertyBaseRouter } from '@/router';
import accessGroupModel from '@/components/view/pm/access-group-model';
import { access, community } from '@/data';
import HttpRequest from '@/util/axios.config';
import noticeBase from '@/util/notice.base';

export default defineComponent({
    components: {
        propertyBreadCrumb,
        accessGroupModel
    },
    props: {
        roomId: {
            type: [String, null] as PropType<string | null>,
            default: null
        },
        id: {
            type: String,
            required: true
        },
        departmentId: {
            type: [String, null] as PropType<string | null>,
            default: null
        },
        role: {
            type: [String, null] as PropType<string | null>,
            default: null
        }
    },
    setup(props) {
        const breadHeaders = ref([{
            label: WordList.RDeviceResidents,
            path: `/${propertyBaseRouter}/${propertySubRouter.resident}`
        }, {
            label: WordList.ProperAllTextEditAccessGroup
        }]);
        watch(community.ProjectType, () => {
            if (community.ProjectType.value === 'office') {
                breadHeaders.value[0].label = WordList.ProperAllTextPeople;
            }
        }, {
            immediate: true
        });

        const accessGreoup: Ref<Array<string>> = ref([]);
        const initAccess: Ref<Array<string>> = ref([]);
        access.getPropertyAllUserAccess(props.id, (data: {
            AccessGroup: { ID: string }[];
        }) => {
            data.AccessGroup.forEach((item) => {
                initAccess.value.push(item.ID);
            });
        });

        const multipleSelect = (data: string[]) => {
            accessGreoup.value = [];
            data.forEach((item) => {
                accessGreoup.value.push(item);
            });
        };
        console.log(1);
        console.log(1);

        const submit = () => {
            if (accessGreoup.value.length === 0) {
                noticeBase.messageBox('alert', WordList.ProperAllTextDelOrStaffAccessGroupTip, WordList.PersonuserInfo, 'info')(() => false);
                return;
            }
            HttpRequest.post(`v3/web/${community.ProjectType.value}/access/editUserAccessGroup`, {
                ID: props.id,
                AccessGroup: accessGreoup.value
            }, () => {
                router.push(`/${propertyBaseRouter}/${propertySubRouter.residentInfo}?id=${props.id}&type=${community.ProjectType.value}`);
            });
        };

        return {
            breadHeaders,
            accessGreoup,
            initAccess,
            multipleSelect,
            submit
        };
    }
});
